// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary: #008000;
$secondary: #4caf50;
$accent: #0091ea;

$dark: #1d1d1d;

$positive: #21BA45;
$negative: #C10015;
$info: #31CCEC;
$warning: #F2C037;

$typography-font-family: 'Overpass', '-apple-system', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$h1: (size: 2.5rem, line-height: 1.2, weight: 300);
$h2: (size: 2rem, line-height: 1.2, weight: 300);
$h3: (size: 1.75rem, line-height:1.2, weight: 400);
$h4: (size: 1.5rem, line-height: 1.2, weight: 400);
$h5: (size: 1.25rem, line-height: 1.2, weight: 400);
$h6: (size: 1rem, line-height: 1.2, weight: 500);
